import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  Snackbar,
  CircularProgress,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import { CreateDemoItemDialog } from "./dialogs/CreateDemoItemDialog";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../req_utils";
import { SUPPORT_EMAIL, GENESIS_LOGO_COLOR } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  updateProjectKeepClean,
  updateSelectedProposalKeepClean,
} from "../reducers";

function DemoItems({ value, index }) {
  const dispatchState = useDispatch();
  const { project_id } = useParams();
  const project = useSelector((state) => state.project);
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const [demoItems, setDemoItems] = useState([]);
  const [selectedDemoItems, setSelectedDemoItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [gridKey, setGridKey] = useState(false);
  const [editableDemoItem, setEditableDemoItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [demoItemsLoading, setDemoItemsLoading] = useState(false);
  const [isExistingProposal, setIsExistingProposal] = useState(false);

  const columns = [
    { field: "labor_item_type", headerName: "Labor Type", flex: 4 },
    { field: "type", headerName: "Item", flex: 4 },
    { field: "quantity", headerName: "Quantity", flex: 2 },
    { field: "size_unit", headerName: "Unit", flex: 2 },
    { field: "unit_price", headerName: "Unit Price", flex: 2 },
    { field: "total_price", headerName: "Total Price", flex: 2 },
    {
      field: "edit",
      headerName: "",
      renderCell: (row) => (
        <IconButton
          onClick={() => {
            setEditableDemoItem(
              demoItems.filter((demoItem) => demoItem.id === row.id)[0],
            );
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
      width: 65,
    },
  ];

  const handleDialogClose = () => {
    setSelectedDemoItems([]);
    setOpen(false);
    setEditableDemoItem(null);
    setGridKey(!gridKey); // Force re-render
  };

  const updateProjectLaborSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateProjectKeepClean({
        ...project,
        labor_subtotal: project.labor_subtotal + itemTotalDiff,
      }),
    );
  };

  const updateProposalLaborSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateSelectedProposalKeepClean({
        ...selectedProposal,
        labor_subtotal: selectedProposal.labor_subtotal + itemTotalDiff,
      }),
    );
  };

  const listProjectDemoItems = (isProposal) => {
    setDemoItemsLoading(true);
    decoratedFetch(
      `/list_project_demo_items?${isProposal ? "proposal_id" : "project_id"}=${isProposal ? selectedProposal.id : project_id}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setDemoItemsLoading(false);
        setDemoItems(data);
      })
      .catch((error) => {
        setDemoItemsLoading(false);
        console.error(`Error listing project labor items: ${error}`);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    const isProposal =
      selectedProposal &&
      Object.keys(selectedProposal).length !== 0 &&
      selectedProposal.id !== "new_proposal";
    listProjectDemoItems(isProposal);
    setIsExistingProposal(isProposal);
  }, [value, selectedProposal]);

  const handleDialogSubmit = (totalPrice, prevTotalPrice, isProposal) => {
    if (isProposal) {
      updateProposalLaborSubtotal(totalPrice - prevTotalPrice);
    } else {
      updateProjectLaborSubtotal(totalPrice - prevTotalPrice);
    }
    listProjectDemoItems(isProposal);
  };

  const handleRemoveDemoItems = () => {
    setLoading(true);
    decoratedFetch(`/delete_project_demo_items`, {
      method: "POST",
      body: JSON.stringify({
        project_id: project_id,
        proposal_id: isExistingProposal ? selectedProposal.id : "",
        demo_item_ids: selectedDemoItems,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        const selectedDemoItemTotalPrices = demoItems
          .filter((demoItem) => selectedDemoItems.includes(demoItem.id))
          .map((demoItem) => parseFloat(demoItem.total_price));
        setDemoItems(
          demoItems.filter(
            (demoItem) => !selectedDemoItems.includes(demoItem.id),
          ),
        );
        const positiveDiff = selectedDemoItemTotalPrices.reduce(
          (sum, totalPrice) => sum + totalPrice,
          0,
        );
        if (isExistingProposal) {
          updateProposalLaborSubtotal(-1 * positiveDiff);
        } else {
          updateProjectLaborSubtotal(-1 * positiveDiff);
        }
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `Labor item${selectedDemoItems.length > 1 ? "s" : ""} deleted`,
        );
        setSelectedDemoItems([]);
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error deleting project labor items: ${error}`);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `An error occurred when deleting labor item${selectedDemoItems.length > 1 ? "s" : ""}`,
        );
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <CreateDemoItemDialog
        demoItem={editableDemoItem}
        isOpen={open}
        handleClose={handleDialogClose}
        handleSubmit={handleDialogSubmit}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        proposalId={isExistingProposal ? selectedProposal.id : ""}
      />
      <div style={{ height: "500px", width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="right"
          sx={{ marginBottom: "10px" }}
        >
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedDemoItems.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton
                aria-label="remove-labor-item"
                onClick={handleRemoveDemoItems}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="add-labor-item"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DataGrid
          key={gridKey}
          rows={demoItems.map((item) => ({
            id: item.id,
            type: item.demo_item_type,
            labor_item_type: item.labor_item_type.name,
            size_unit: item.size_unit?.name,
            unit_price: `$${item?.unit_price}`,
            quantity: item?.quantity,
            total_price: `$${item?.total_price}`,
          }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[10]}
          onRowSelectionModelChange={(newSelectionModel) =>
            setSelectedDemoItems(newSelectionModel)
          }
          getRowId={(row) => row.id}
          selectionModel={selectedDemoItems}
          checkboxSelection
          localeText={{ noRowsLabel: "No labor items" }}
          loading={demoItemsLoading}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
              {
                outline: "none",
              },
          }}
        />
      </div>
    </>
  );
}

export default DemoItems;
