import React, { useRef, useState, useCallback, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { decoratedFetch } from "../../req_utils";
import { SUPPORT_EMAIL } from "../../constants";

const DIVIDER_COLOR = "#D3D3D3";

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(
      () => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      },
      Math.random() * 500 + 100,
    ); // simulate network latency
  });
}

const CreateProposalTemplateDialog = ({
  open,
  handleClose: handleCloseProp,
  handleSubmit: handleSubmitProp,
  proposalTemplate,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedInclusions, setSelectedInclusions] = useState([]);
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [nextInclusionId, setNextInclusionId] = useState(1);
  const [nextExclusionId, setNextExclusionId] = useState(1);
  const [nextNoteId, setNextNoteId] = useState(1);
  const [lastAddedInclusionId, setLastAddedInclusionId] = useState(null);
  const [lastAddedExclusionId, setLastAddedExclusionId] = useState(null);
  const [lastAddedNoteId, setLastAddedNoteId] = useState(null);
  const inclusionsApiRef = useGridApiRef();
  const exclusionsApiRef = useGridApiRef();
  const notesApiRef = useGridApiRef();
  const enterPressedRef = useRef(false);
  const [cmasId, setCmasId] = useState('');

  const descriptionColumns = [
    {
      field: "description",
      headerName: "Description",
      editable: true,
      flex: 1,
    },
  ];

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseProp();
  };

  const handleDelete = () => {
    setLoading(true);
    const url_suffix = `/delete_proposal_template/${proposalTemplate.id}`;
    decoratedFetch(url_suffix, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          handleSubmitProp();
          setSnackbarOpen(true);
          setSnackbarMessage(`Proposal template deleted`);
          return;
        } else {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarOpen(true);
        setSnackbarMessage(`Failed to delete proposal template`);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (proposalTemplate) {
      setFetchingData(true);
      setTemplateName(proposalTemplate.name || "");
      decoratedFetch(`/look_up_proposal_template?id=${proposalTemplate.id}`)
        .then((response) => response.json())
        .then((data) => {
          const inclusionsWithIds =
            data?.inclusions?.map((description, index) => ({
              id: index,
              description,
            })) || [];
          setNextInclusionId(inclusionsWithIds.length);
          const exclusionsWithIds =
            data?.exclusions?.map((description, index) => ({
              id: index,
              description,
            })) || [];
          setNextExclusionId(exclusionsWithIds.length);
          const notesWithIds =
            data?.notes?.map((description, index) => ({
              id: index,
              description,
            })) || [];
          setNextNoteId(notesWithIds.length);

          setInclusions(inclusionsWithIds);
          setExclusions(exclusionsWithIds);
          setNotes(notesWithIds);
          setFetchingData(false);
          setCmasId(data?.cmas_id);
        })
        .catch((error) => {
          console.error(`Error listing proposal templates: ${error}`);
          setFetchingData(false);
        });
    } else {
      setTemplateName("");
      setExclusions([]);
      setInclusions([]);
      setNotes([]);
      setCmasId('');
    }
  }, [proposalTemplate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!templateName) {
      setAlertMessage("Template name is required.");
      setLoading(false);
      return;
    }
    const api = proposalTemplate
      ? `/update_proposal_template/${proposalTemplate.id}`
      : "/create_proposal_template";
    decoratedFetch(api, {
      method: proposalTemplate ? "PUT" : "POST",
      body: JSON.stringify({
        name: templateName,
        inclusions: inclusions.map((inclusion) => inclusion.description),
        exclusions: exclusions.map((exclusion) => exclusion.description),
        notes: notes.map((note) => note.description),
        cmas_id: cmasId,
      }),
    })
      .then((response) => {
        if (!proposalTemplate && response.status === 201) {
          return;
        }
        if (proposalTemplate && response.status === 200) {
          return;
        }
        switch (response.status) {
          case 400:
            setAlertMessage("Template name is required.");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then(() => {
        setLoading(false);
        handleSubmitProp();
        setSnackbarOpen(true);
        setSnackbarMessage(
          `Proposal Template ${proposalTemplate ? "updated" : "created"}`,
        );
      })
      .catch((error) => {
        console.error(
          `Error ${proposalTemplate ? "editing" : "creating"} proposal template: ${error}`,
        );
        setLoading(false);
      });
  };

  const handleAddNoteRow = () => {
    const newRow = { id: nextNoteId, description: "" };
    setNotes([...notes, newRow]);
    setLastAddedNoteId(nextNoteId);
    setNextNoteId(nextNoteId + 1);
  };

  const handleNoteRowDelete = () => {
    setNotes((prevRows) =>
      prevRows.filter((row) => !selectedNotes.includes(row.id)),
    );
    setSelectedNotes([]); // Clear selection after deletion
  };

  const handleNoteRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedNotes(newSelectionModel);
    },
    [setSelectedNotes],
  );

  const handleNoteEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      enterPressedRef.current = false;
      setNotes(notes.filter((note) => note.id !== updatedRow.id));
      return updatedRow;
    }
    const newRows = notes.map((row) => {
      if (row.id === updatedRow.id) {
        return { ...row, ...updatedRow };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === notes[notes.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = { id: nextNoteId, description: "" };
      setLastAddedNoteId(nextNoteId);
      setNextNoteId(nextNoteId + 1);
      newRows.push(newRow);
    }
    setNotes(newRows);
    enterPressedRef.current = false;
    return updatedRow;
  };

  const handleInclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedInclusions(newSelectionModel);
    },
    [setSelectedInclusions],
  );

  const handleExclusionEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      enterPressedRef.current = false;
      setExclusions(
        exclusions.filter((exclusion) => exclusion.id !== updatedRow.id),
      );
      return updatedRow;
    }
    const newExclusions = exclusions.map((row) => {
      if (row.id === updatedRow.id) {
        return { ...row, ...updatedRow };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === exclusions[exclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = { id: nextExclusionId, description: "" };
      setLastAddedExclusionId(nextExclusionId);
      setNextExclusionId(nextExclusionId + 1);
      newExclusions.push(newRow);
    }
    setExclusions(newExclusions);
    enterPressedRef.current = false;
    return updatedRow;
  };

  const handleAddExclusionRow = () => {
    const newRow = { id: nextExclusionId, description: "" };
    setExclusions([...exclusions, newRow]);
    setLastAddedExclusionId(nextExclusionId);
    setNextExclusionId(nextExclusionId + 1);
  };

  const handleExclusionRowDelete = () => {
    setExclusions((prevExclusions) =>
      prevExclusions.filter((row) => !selectedExclusions.includes(row.id)),
    );
    setSelectedExclusions([]); // Clear selection after deletion
  };

  const handleExclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedExclusions(newSelectionModel);
    },
    [setSelectedExclusions],
  );

  const handleCellKeyDown = (params, event) => {
    if (event.key !== "Enter") {
      enterPressedRef.current = false;
      return;
    }
    enterPressedRef.current = true;
  };

  const handleInclusionEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      enterPressedRef.current = false;
      setInclusions(
        inclusions.filter((inclusion) => inclusion.id !== updatedRow.id),
      );
      return updatedRow;
    }
    const newRows = inclusions.map((row) => {
      if (row.id === updatedRow.id) {
        return {
          id: row.id,
          description: updatedRow.description,
        };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === inclusions[inclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: nextInclusionId,
        description: "",
      };
      setLastAddedInclusionId(nextInclusionId);
      setNextInclusionId(nextInclusionId + 1);
      newRows.push(newRow);
    }
    setInclusions(newRows);
    enterPressedRef.current = false;
    return updatedRow;
  };

  useEffect(() => {
    if (lastAddedInclusionId && inclusionsApiRef.current) {
      inclusionsApiRef.current.setCellFocus(
        lastAddedInclusionId,
        "description",
      );
      inclusionsApiRef.current.startCellEditMode({
        id: lastAddedInclusionId,
        field: "description",
      });
      setLastAddedInclusionId(null);
    }
  }, [lastAddedInclusionId]);

  useEffect(() => {
    if (lastAddedExclusionId && exclusionsApiRef.current) {
      exclusionsApiRef.current.setCellFocus(
        lastAddedExclusionId,
        "description",
      );
      exclusionsApiRef.current.startCellEditMode({
        id: lastAddedExclusionId,
        field: "description",
      });
      setLastAddedExclusionId(null);
    }
  }, [lastAddedExclusionId]);

  useEffect(() => {
    if (lastAddedNoteId && notesApiRef.current) {
      notesApiRef.current.setCellFocus(lastAddedNoteId, "description");
      notesApiRef.current.startCellEditMode({
        id: lastAddedNoteId,
        field: "description",
      });
      setLastAddedNoteId(null);
    }
  }, [lastAddedNoteId]);

  const handleAddInclusionRow = () => {
    const newRow = {
      id: nextInclusionId,
      description: "",
    };
    setInclusions([...inclusions, newRow]);
    setLastAddedInclusionId(nextInclusionId);
    setNextInclusionId(nextInclusionId + 1);
  };

  const handleRowDelete = () => {
    setInclusions((prevRows) =>
      prevRows.filter((row) => !selectedInclusions.includes(row.id)),
    );
    setSelectedInclusions([]); // Clear selection after deletion
  };

  const handleRowOrderChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      inclusions,
    );

    setInclusions(newRows);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        disableEscapeKeyDown
        disableScrollLock
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {proposalTemplate
            ? "Edit Proposal Template"
            : "Create Proposal Template"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "white",
            }}
          >
            {alertMessage && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Alert
                  onClose={() => setAlertMessage("")}
                  severity={"error"}
                  sx={{
                    width: "80%",
                    borderRadius: 8,
                    marginBottom: "15px",
                    marginTop: "4px",
                  }}
                >
                  {alertMessage}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: "10px", mb: "22px" }}>
              <TextField
                label="Template Name"
                variant="outlined"
                fullWidth
                size="small"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: "11px" }}>
              <Divider sx={{ backgroundColor: DIVIDER_COLOR }} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "24px",
                }}
              >
                <TextField
                  label="CMAS ID"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={cmasId}
                  onChange={e => setCmasId(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "1.2rem" }}>Inclusions</Typography>
                {fetchingData ? (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <CircularProgress
                        size="22px"
                        sx={{ mr: "8px", mt: "12px", mb: "-1px" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      sx={{
                        marginRight: "4px",
                        visibility: selectedInclusions.length
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      <IconButton
                        aria-label="remove-inclusion"
                        onClick={handleRowDelete}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="add-inclusion"
                        onClick={handleAddInclusionRow}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
                {inclusions.length ? (
                  <DataGridPro
                    apiRef={inclusionsApiRef}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        display: "none",
                      },
                    }}
                    hideFooter
                    rows={inclusions}
                    columns={descriptionColumns}
                    processRowUpdate={handleInclusionEditCommit}
                    onProcessRowUpdateError={(error) => console.error(error)}
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    onRowSelectionModelChange={(newSelectionModel) =>
                      handleInclusionRowSelectionModelChange(newSelectionModel)
                    }
                    getRowId={(row) => row.id}
                    onCellClick={(params, e) => e.stopPropagation()}
                    localeText={{ noRowsLabel: "No Inclusions" }}
                    onCellKeyDown={handleCellKeyDown}
                    rowReordering
                    onRowOrderChange={(e) => {
                      let mutableInclusions = [...inclusions];
                      const sourceRow = mutableInclusions[e.oldIndex];
                      mutableInclusions.splice(e.oldIndex, 1);
                      mutableInclusions.splice(e.targetIndex, 0, sourceRow);
                      setInclusions(mutableInclusions);
                    }}
                  />
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "1.2rem" }}>Exclusions</Typography>
                {fetchingData ? (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <CircularProgress
                        size="22px"
                        sx={{ mr: "8px", mt: "12px", mb: "-1px" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      sx={{
                        marginRight: "4px",
                        visibility: selectedExclusions.length
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      <IconButton
                        aria-label="remove-exclusion-item"
                        onClick={handleExclusionRowDelete}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="add-exclusion-item"
                        onClick={handleAddExclusionRow}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
                {exclusions.length ? (
                  <DataGridPro
                    apiRef={exclusionsApiRef}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        display: "none",
                      },
                    }}
                    hideFooter
                    rows={exclusions}
                    columns={descriptionColumns}
                    processRowUpdate={handleExclusionEditCommit}
                    onProcessRowUpdateError={(error) => console.error(error)}
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    onRowSelectionModelChange={
                      handleExclusionRowSelectionModelChange
                    }
                    getRowId={(row) => row.id}
                    onCellClick={(params, e) => e.stopPropagation()}
                    onCellKeyDown={handleCellKeyDown}
                    localeText={{ noRowsLabel: "No Exclusions" }}
                    rowReordering
                    onRowOrderChange={(e) => {
                      let mutableExclusions = [...exclusions];
                      const sourceRow = mutableExclusions[e.oldIndex];
                      mutableExclusions.splice(e.oldIndex, 1);
                      mutableExclusions.splice(e.targetIndex, 0, sourceRow);
                      setExclusions(mutableExclusions);
                    }}
                  />
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "1.2rem" }}>Notes</Typography>
                {fetchingData ? (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <CircularProgress
                        size="22px"
                        sx={{ mr: "8px", mt: "12px", mb: "-1px" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      sx={{
                        marginRight: "4px",
                        visibility: selectedNotes.length ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        aria-label="remove-description-item"
                        onClick={handleNoteRowDelete}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="add-description-item"
                        onClick={handleAddNoteRow}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: "11px", width: "100%" }}>
                {notes.length ? (
                  <DataGridPro
                    apiRef={notesApiRef}
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        display: "none",
                      },
                    }}
                    hideFooter
                    rows={notes}
                    columns={descriptionColumns}
                    processRowUpdate={handleNoteEditCommit}
                    onProcessRowUpdateError={(error) => console.error(error)}
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    onRowSelectionModelChange={
                      handleNoteRowSelectionModelChange
                    }
                    getRowId={(row) => row.id}
                    rowReordering
                    onRowOrderChange={(e) => {
                      let mutableNotes = [...notes];
                      const sourceRow = mutableNotes[e.oldIndex];
                      mutableNotes.splice(e.oldIndex, 1);
                      mutableNotes.splice(e.targetIndex, 0, sourceRow);
                      setNotes(mutableNotes);
                    }}
                    onCellKeyDown={handleCellKeyDown}
                    localeText={{ noRowsLabel: "No Notes" }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{pt: '15px'}}>
          {loading ? (
            <CircularProgress
              size="25px"
              sx={{ mt: "3px", mb: "7px", mr: "20px" }}
            />
          ) : (
            <div>
              <Button sx={{ mr: "10px" }} onClick={handleClose}>
                Cancel
              </Button>
              {proposalTemplate && (
                <Button
                  sx={{ mr: "10px" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
              <Button variant="contained" onClick={handleSubmit} sx={{ mr: "10px" }}>
                {proposalTemplate ? "Save" : "Create"}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateProposalTemplateDialog;
