import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Grid,
  Link,
  Divider,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { updateProject } from "../reducers";
import FileTab from "../components/FileTab";
import LogTab from "./LogTab";

dayjs.extend(utc);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectDetailsTab = ({ projectData }) => {
  const dispatchState = useDispatch();
  const project = useSelector((state) => state.project);
  const [tabValue, setTabValue] = useState(0);
  const [branchAddresses, setBranchAddresses] = useState([]);
  const [branchAddressId, setBranchAddressId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [measuredById, setMeasuredById] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBranchBlur = () => {
    dispatchState(
      updateProject({
        ...project,
        genesis_branch_address: branchAddresses.filter(
          (branchAddress) => branchAddress.id === branchAddressId,
        )[0],
      }),
    );
  };

  const handleMeasuredByBlur = () => {
    dispatchState(
      updateProject({
        ...project,
        measured_by_employee: employees.filter(
          (employee) => employee.id === measuredById,
        )[0],
      }),
    );
  };

  useEffect(() => {
    setBranchAddressId(project?.genesis_branch_address?.id || "");
    setMeasuredById(project?.measured_by_employee?.id || "");
  }, [project]);

  useEffect(() => {
    if (tabValue !== 0) {
      // If project info tab is not selected
      return;
    }
    decoratedFetch("/list_employees")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setEmployees(data);
        } else {
          setEmployees([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        // Set an empty array in case of an error
        setEmployees([]);
      });
    decoratedFetch("/list_branch_addresses")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setBranchAddresses(data);
        } else {
          setBranchAddresses([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setBranchAddresses([]);
      });
  }, [tabValue]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            aria-label="project-details-tab"
          >
            <Tab label="Log" {...a11yProps(2)} />
            <Tab label="Files" {...a11yProps(1)} />
            <Tab label="Project Info" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={2} paddingTop={0.9}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <FormControl
                sx={{
                  minWidth: 100,
                  width: "100%",
                }}
              >
                <InputLabel id="branch-select-label">
                  Genesis Branch Address
                </InputLabel>
                <Select
                  labelId="branch-select-label"
                  id="branch-select"
                  value={branchAddressId}
                  onChange={(e) => {
                    setBranchAddressId(e.target.value);
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  }}
                  onBlur={handleBranchBlur}
                  label="Genesis Branch Address"
                  fullWidth
                  renderValue={(selectedId) => {
                    const selected = branchAddresses.find(
                      (branchAddress) => branchAddress.id === selectedId,
                    );
                    return selected
                      ? `${selected.line_1}, ${selected.city} ${selected.state} ${selected.zip}`
                      : "";
                  }}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {branchAddresses.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.line_1}, {branch.city} {branch.state} {branch.zip}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Proposal Date"
                  value={
                    project?.start_date ? dayjs.utc(project?.start_date) : null
                  }
                  size="small"
                  onChange={(newStartDate) =>
                    dispatchState(
                      updateProject({ ...project, start_date: newStartDate }),
                    )
                  }
                  sx={{
                    width: "100%",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Measured By</InputLabel>
                <Select
                  id="mb-select"
                  value={measuredById}
                  onChange={(e) => {
                    setMeasuredById(e.target.value);
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  }}
                  onBlur={handleMeasuredByBlur}
                  label="Measured By"
                  fullWidth
                  renderValue={(selectedId) => {
                    const selected = employees.find((e) => e.id === selectedId);
                    return selected
                      ? `${selected.first_name} ${selected.last_name}`
                      : "";
                  }}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {employees
                    .filter((employee) => employee.enabled) // Keep only enabled employees
                    .map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.first_name} {employee.last_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FileTab value={tabValue} index={1} />
        </TabPanel>
        <TabPanel value={tabValue} index={0}>
          <LogTab
            value={tabValue}
            index={0}
            projectName={project.name}
            projectCustomId={project.custom_id}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default ProjectDetailsTab;
