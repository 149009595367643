import React, { useState } from "react";
import {
  Grid,
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import Alert from "@mui/material/Alert";
import roles from "../../data/roles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export function InviteUserModal({
  isOpen,
  handleClose,
  content,
  setSnackbarOpen,
  setSnackbarMessage,
  handleUserInvite,
}) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleRoleChange = (event) => setRole(event.target.value);

  const handleSend = () => {
    setLoading(true);

    const url_suffix = "/create_employee";

    const payload = {
      email: email,
      type: role,
    };
    decoratedFetch(url_suffix, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false); // Stop loading
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage("User invited");
        handleUserInvite();
      })
      .catch((error) => {
        console.error("Error:", error); //Log the error
        setLoading(false); // Stop loading in case of error
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage("Error inviting user");
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(""); // Clear success message
  };

  return (
    <div>
      <Dialog
        disableScrollLock
        fullWidth
        open={isOpen}
        onClose={() => {
          handleClose();
          setAlertMessage("");
        }}
        sx={{
          width: "500px", // Static width
          margin: "auto", // Automatically margining for horizontal centering
          maxWidth: "100%", // Ensuring the max width doesn't exceed viewport width
        }}
      >
        <DialogTitle
          sx={{ fontSize: "22px", paddingBottom: "0px", flexGrow: 1 }}
        >
          Invite User
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="row" marginTop={1}>
            {alertMessage && (
              <Grid item xs={12}>
                <Alert
                  onClose={handleAlertClose}
                  severity={severity}
                  sx={{ width: "100%", borderRadius: 8 }}
                >
                  {alertMessage}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 120,
                  width: "100%",
                }}
              >
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  value={role}
                  onChange={handleRoleChange}
                  label="Role"
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "5px", marginLeft: "10px" }}>
          {loading ? (
            <CircularProgress
              size="25px"
              sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "20px" }}
            />
          ) : (
            <div sx={{ float: "right" }}>
              <Button
                sx={{ marginRight: "10px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSend}
                disabled={alertMessage}
              >
                Send
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
