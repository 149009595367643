import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UsersTab from "../components/UsersTab";
import SavedMaterials from "../components/SavedMaterials";
import SavedLaborItems from "../components/SavedLaborItems";
import ProposalTemplates from "../components/ProposalTemplates";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ margin: "20px", paddingTop: "20px" }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ fontWeight: "bold", marginBottom: "30px" }}
      >
        Admin
      </Typography>
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          <Tab label="Users" />
          <Tab label="Materials" />
          <Tab label="Labor" />
          <Tab label="Proposals" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UsersTab value={value} index={0} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SavedMaterials value={value} index={1} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SavedLaborItems value={value} index={2} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ProposalTemplates value={value} index={3} />
        </TabPanel>
      </Paper>
    </div>
  );
}
