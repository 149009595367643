import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Divider,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { decoratedFetch } from "../../req_utils";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";

const LABEL_COLOR = "#7A7A7A";
const BORDER_COLOR = "#C5C5C5";

export function CreateMaterialItemDialog({
  isOpen,
  handleClose: handleCloseProp,
  materialItem,
  handleSubmit: handleSubmitProp,
  setSnackbarOpen,
  setSnackbarMessage,
  new_main_item_id,
  proposalId,
}) {
  let { project_id } = useParams();
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [displayQuantity, setDisplayQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [color, setColor] = useState("");
  const [colors, setColors] = useState([]);
  const [style, setStyle] = useState("");
  const [styles, setStyles] = useState([]);
  const [size, setSize] = useState("");
  const [freight, setFreight] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState(parseFloat(0).toFixed(2));
  const [main_item_id, setMainId] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [isAddToSavedMaterialsChecked, setAddToSavedMaterials] =
    useState(false);
  const [isAddToSavedChecked, setIsAddToSavedChecked] = useState(false);
  const [savedMaterialItems, setSavedMaterialItems] = useState([]);
  const [savedMaterial, setSavedMaterial] = useState({});

  const handleCheckboxChange = (event) => {
    setIsAddToSavedChecked(event.target.checked);
  };

  const calculateTotalPrice = () => {
    const parsedQuantity = parseFloat(quantity) || 0;
    const parsedUnitPrice = parseFloat(unitPrice) || 0;
    const parsedFreight = parseFloat(freight) || 0;

    return (parsedQuantity * (parsedUnitPrice + parsedFreight)).toFixed(2);
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [quantity, unitPrice, freight]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (materialItem) {
      initializeFromMaterialItemProp();
    } else {
      resetFields();
      if (new_main_item_id) {
        setMainId(new_main_item_id);
      }
    }
    decoratedFetch("/list_material_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setTypes(data))
      .catch((error) =>
        console.error(`Error fetching material types ${error}`),
      );
    decoratedFetch("/list_size_units")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setUnits(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_vendors")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setVendors(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_colors")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setColors(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_material_styles")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setStyles(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
    decoratedFetch("/list_saved_material_items")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setSavedMaterialItems(data);
      })
      .catch((error) => {
        console.error(`Error fetching saved material items: ${error}`);
      });
  }, [isOpen]);

  const resetFields = () => {
    setType("");
    setMainId("");
    setColor("");
    setStyle("");
    setSize("");
    setVendor("");
    setFreight("");
    setQuantity(0);
    setDisplayQuantity("");
    setUnit("");
    setUnitPrice("");
    setTotalPrice(parseFloat(0).toFixed(2));
    setLoading(false);
    setAlertMessage("");
    setNotes("");
    setSavedMaterial(null);
  };

  const initializeFromMaterialItemProp = () => {
    setType(materialItem?.material_type);
    setMainId(materialItem?.main_item_id);
    setStyle(materialItem?.material_style);
    setColor(materialItem?.material_color);
    setVendor(materialItem?.material_vendor);
    setSize(materialItem?.material_size);
    setFreight(materialItem?.freight);
    setQuantity(parseFloat(materialItem?.quantity).toFixed(2));
    setDisplayQuantity(materialItem?.quantity.toString());
    setUnit(materialItem?.size_unit?.id);
    setUnitPrice(materialItem?.unit_price);
    setTotalPrice(materialItem?.total_price);
    setNotes(materialItem?.notes);
  };

  useEffect(() => {
    if (savedMaterial && Object.keys(savedMaterial).length > 0) {
      setType(savedMaterial.material_type || "");
      setVendor(savedMaterial.material_vendor || "");
      setColor(savedMaterial.material_color || "");
      setSize(savedMaterial.material_size || "");
      setStyle(savedMaterial.material_style || "");
      setUnit(savedMaterial.size_unit?.id || "");
      setUnitPrice(savedMaterial.unit_price || "");
    }
  }, [savedMaterial]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!type || !unit) {
      setAlertMessage(
        "Required fields not filled in. Items must have a type and unit",
      );
      setLoading(false);
      return;
    }
    const api = materialItem
      ? `/update_project_material_item/${materialItem.id}`
      : "/create_project_material_item";
    decoratedFetch(api, {
      method: materialItem ? "PUT" : "POST",
      body: JSON.stringify({
        project_id: project_id,
        proposal_id: proposalId,
        material_type: type,
        material_size: size,
        material_color: color,
        material_vendor: vendor,
        material_style: style,
        freight: freight,
        quantity: quantity,
        unit: unit,
        unit_price: unitPrice ? unitPrice : parseFloat(0).toFixed(2),
        total_price: totalPrice,
        main_item_id: main_item_id,
        notes: notes,
        save: isAddToSavedChecked,
      }),
    })
      .then((response) => {
        if (!materialItem && response.status === 201) {
          return;
        }
        if (materialItem && response.status === 200) {
          return;
        }
        switch (response.status) {
          case 400:
            return response.json().then(errorPayload => {
              if (errorPayload.msg) {
                setAlertMessage(errorPayload.msg);
              }
              throw new Error();
            });
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then(() => {
        setLoading(false);
        handleSubmitProp(
          parseFloat(totalPrice),
          materialItem ? parseFloat(materialItem.total_price) : 0,
          proposalId,
        );
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(`Material ${materialItem ? "updated" : "created"}`);
      })
      .catch((error) => {
        console.error(
          `Error ${materialItem ? "editing" : "creating"} material: ${error}`,
        );
        setLoading(false);
      });
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetFields();
    setAlertMessage("");
    handleCloseProp();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <Dialog
      disableScrollLock
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: "650px" }, // Targets the inner Paper component
      }}
    >
      <DialogTitle sx={{ fontSize: "22px", paddingBottom: "0px" }}>
        {new_main_item_id
          ? new_main_item_id
            ? "Create Sub Material"
            : "Edit Material"
          : "Create Material"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" sx={{ marginTop: "2px" }}>
          {alertMessage && (
            <Grid item xs={12}>
              <Alert
                onClose={handleAlertClose}
                severity="error"
                sx={{ width: "100%", borderRadius: 8 }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          {!materialItem && (
            <Grid item xs={12}>
              <Autocomplete
                id="saved-material-autocomplete"
                options={savedMaterialItems}
                getOptionLabel={(option) =>
                  `${option.material_type || ""} ${option?.material_style || ""} ${option?.material_vendor || ""} ${option?.material_size || ""} ${option?.material_color || ""} ${option?.size_unit?.name || ""} ${option?.unit_price || ""}`
                }
                value={savedMaterial}
                onChange={(event, newValue) => {
                  setSavedMaterial(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Saved Material"
                    variant="outlined"
                    fullWidth
                    placeholder="Select saved material item"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Autocomplete
              id="Type"
              required
              value={type}
              freeSolo
              options={types.map((option) => option.display_name)}
              renderInput={(params) => <TextField {...params} label="Type" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setType(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setType(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Vendor"
              value={vendor}
              freeSolo
              options={vendors.map((option) => option.display_name)}
              renderInput={(params) => (
                <TextField {...params} label="Manufacturer" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setVendor(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setVendor(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Style"
              value={style}
              freeSolo
              options={styles.map((option) => option.display_name)}
              renderInput={(params) => (
                <TextField {...params} label="Product" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setStyle(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setStyle(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Color"
              value={color}
              freeSolo
              options={colors.map((option) => option.display_name)}
              renderInput={(params) => <TextField {...params} label="Color" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setColor(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setColor(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Size"
              fullWidth
              variant="outlined"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Quantity"
              type="number"
              value={displayQuantity}
              onChange={(e) => {
                const displayVal = e.target.value;
                const floatVal = displayVal ? parseFloat(displayVal).toFixed(2) : 0;
                if (floatVal < 0) {
                  return;
                }
                setDisplayQuantity(displayVal);
                setQuantity(floatVal);
              }}
              InputLabelProps={{ shrink: displayQuantity }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Unit</InputLabel>
              <Select
                id="unit-select"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                label="Unit"
                fullWidth
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Unit Price"
              id="unit price"
              type="number"
              value={unitPrice}
              onChange={(e) => {
                const val = e.target.value;
                if (val < 0) {
                  return;
                }
                setUnitPrice(val);
              }}
              onBlur={() =>
                setUnitPrice(parseFloat(unitPrice).toFixed(2).toString())
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="Notes"
              fullWidth
              multiline
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            {project_id && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAddToSavedChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Add to Saved Materials"
                sx={{ width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          margin: "5px",
          marginLeft: "10px",
        }}
      >
        <Typography
          variant="p"
          id="total-material-item-price"
          sx={{ float: "left" }}
        >
          Total price: <b>${totalPrice}</b>
        </Typography>
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "20px" }}
          />
        ) : (
          <div>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={alertMessage}
            >
              {materialItem ? "Save" : "Create"}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
