import React, { useEffect, useState } from "react";
import UsersTable from "../components/UsersTable";
import { Snackbar, Button, Box } from "@mui/material";
import { InviteUserModal } from "../components/modals/InviteUserModal";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";
import Alert from "@mui/material/Alert";

const UsersTab = ({ value, index }) => {
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [employeesLoading, setEmployeesLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  const updateEmployeeStatus = (id, enabled) => {
    const updatedEmployees = employees.map((emp) =>
      emp.id === id ? { ...emp, enabled: enabled } : emp,
    );
    setEmployees(updatedEmployees);
  };

  const listEmployees = () => {
    decoratedFetch("/list_employees?include_incomplete_signups=true")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setEmployeesLoading(false);
        setEmployees(data);
      })
      .catch((error) => {
        setEmployeesLoading(false);
        console.error(`Error fetching data: ${error}`);
      });
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    setEmployeesLoading(true);
    listEmployees();
  }, []);

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <InviteUserModal
        isOpen={open}
        handleClose={handleClose}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        handleUserInvite={listEmployees}
      />
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={handleOpen}
          >
            Invite User
          </Button>
        </div>
        <UsersTable
          employees={employees}
          employeesLoading={employeesLoading}
          onStatusChange={updateEmployeeStatus}
        />
      </div>
    </>
  );
};

export default UsersTab;
