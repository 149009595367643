import { GENESIS_API_BASE_URL } from "./constants";
import axios from "axios";
import { store } from "./store";
export const decoratedFetch = (
  url_suffix,
  options = {},
  no_refresh = false,
) => {
  options.credentials = "include"; // Include cookies
  options.headers = {
    ...options.headers,
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest", // Protect against CSRF, but slows down website because all requests require a CORS preflight request. More efficient option is sending the access token in the request body
  };
  return fetch(GENESIS_API_BASE_URL + url_suffix, options).then((response) => {
    if (response.status === 403) {
      // Account has been disabled
      store.dispatch({ type: "mark_as_unauthenticated" });
      return response;
    } else if (response.status !== 401 || no_refresh) {
      return response;
    } else {
      // Attempt to refresh the token
      return fetch(GENESIS_API_BASE_URL + "/refresh", {
        ...options,
        method: "POST",
      }).then((res) => {
        if (res.ok) {
          // If the refresh was successful, retry the original request
          return fetch(GENESIS_API_BASE_URL + url_suffix, options);
        } else {
          // If refresh fails, mark as unauthenticated and reject the promise
          store.dispatch({ type: "mark_as_unauthenticated" });
        }
      });
    }
  });
};

export const decoratedAxiosPost = (url_suffix, payload, options = {}) => {
  options.headers = {
    ...options.headers,
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest", // Protect against CSRF, but slows down website because all requests require a CORS preflight request. More efficient option is sending the access token in the request body
  };
  return axios
    .post(GENESIS_API_BASE_URL + url_suffix, payload, {
      ...options,
      withCredentials: true,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (!error.response || error.response.status !== 401) {
        throw error;
      } else {
        // If response is 401, attempt to refresh the token
        return fetch(GENESIS_API_BASE_URL + "/refresh", {
          ...options,
          method: "POST",
          credentials: "include",
        }).then((res) => {
          if (res.ok) {
            // If the refresh was successful, retry the original request
            return axios.post(GENESIS_API_BASE_URL + url_suffix, payload, {
              ...options,
              withCredentials: true,
            });
          } else {
            // If refresh fails, mark as unauthenticated and reject the promise
            store.dispatch({ type: "mark_as_unauthenticated" });
          }
        });
      }
    });
};
