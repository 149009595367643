import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateAccountModal } from "../components/modals/CreateAccountModal";
import { decoratedFetch } from "../req_utils";
import EditAccountDialog from "../components/dialogs/EditAccountDialog";
import ContactsTab from "../components/ContactsTab";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

const AccountsPage = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const columns = [
    {
      field: "account_name",
      headerName: "Account Name",
      width: 250,
      editable: false,
      flex: 3,
    },
    {
      field: "line_1",
      headerName: "Address Ln 1",
      width: 240,
      editable: false,
      flex: 3,
    },
    {
      field: "line_2",
      headerName: "Ln 2",
      width: 120,
      editable: false,
      flex: 2,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      editable: false,
      flex: 3,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
      editable: false,
      flex: 1,
    },
    {
      field: "zip",
      headerName: "Zip",
      width: 150,
      editable: false,
      flex: 2,
    },
  ];

  const rows = accounts?.map((account, index) => ({
    id: account.id,
    account_name: account.name,
    line_1: account.address?.line_1,
    line_2: account.address?.line_2,
    city: account.address?.city,
    state: account.address?.state,
    zip: account.address?.zip,
  }));

  const handleRowClick = (params) => {
    setSelectedAccount(accounts.find((account) => account.id === params.id));
    setEdit(true);
  };

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
  };

  useEffect(() => {
    setFilterModel(filterModel);
  }, []);

  useEffect(() => {
    setAccountsLoading(true);
    decoratedFetch("/list_accounts")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setAccountsLoading(false);
        setAccounts(data);
      })
      .catch((error) => {
        setAccountsLoading(false);
        console.error("Error fetching data: ", error);
      });
  }, [trigger]);

  return (
    <>
      <EditAccountDialog
        isOpen={edit}
        handleClose={handleClose}
        account={selectedAccount}
        triggerParentUpdate={triggerParentUpdate}
      />
      <CreateAccountModal
        isOpen={open}
        handleClose={handleClose}
        triggerParentUpdate={triggerParentUpdate}
      />
      <div style={{ margin: "20px", paddingTop: "20px" }}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: "bold" }}>
          Accounts
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%", // Ensure container takes full width of the paper
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add New
          </Button>
        </div>
        <div style={{ height: 600, width: "100%", paddingTop: 10 }}>
          <DataGridPro
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            pageSize={50}
            disableSelectionOnClick
            disableMultipleSelection
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            onRowClick={handleRowClick}
            localeText={{ noRowsLabel: "No accounts" }}
            loading={accountsLoading}
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
                {
                  outline: "none",
                },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AccountsPage;
