import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import {
  Snackbar,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import { CreateMaterialItemDialog } from "./dialogs/CreateMaterialItemDialog";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR, SUPPORT_EMAIL } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  markSelectedProposalClean,
  updateSelectedProposalKeepClean,
  updateProjectKeepClean,
} from "../reducers";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { styled } from "@mui/material/styles";

const CustomRow = styled(GridRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomRowWithTooltip = (props) => {
  const { row, ...other } = props;
  return (
    <Tooltip title={row.notes || ""} arrow>
      <div>
        <CustomRow {...other} />
      </div>
    </Tooltip>
  );
};

const MaterialsTable = React.memo(
  ({
    gridKey,
    rows,
    columns,
    loading,
    getTreeDataPath,
    handleRowSelectionModelChange,
  }) => {
    return (
      <DataGridPro
        key={gridKey}
        rows={rows}
        columns={columns}
        loading={loading}
        treeData
        getTreeDataPath={getTreeDataPath}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        checkboxSelection
        onRowSelectionModelChange={(newSelectionModel) =>
          handleRowSelectionModelChange(newSelectionModel)
        }
        getRowId={(row) => row.id}
        onCellClick={(params, e) => e.stopPropagation()}
        localeText={{ noRowsLabel: "No materials" }}
        groupingColDef={{
          headerName: "",
          width: 300,
          flex: 1,
        }}
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
            {
              outline: "none",
            },
        }}
        components={{
          Row: CustomRowWithTooltip,
        }}
      />
    );
  },
);

function MaterialItems({ value, index }) {
  const dispatchState = useDispatch();
  const { project_id } = useParams();
  const project = useSelector((state) => state.project);
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const [new_main_item_id, setNewMainItemId] = useState("");
  const [materialItems, setMaterialItems] = useState([]);
  const [selectedMaterialItems, setSelectedMaterialItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [gridKey, setGridKey] = useState(false);
  const [editableMaterialItem, setEditableMaterialItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [isExistingProposal, setIsExistingProposal] = useState(false);

  const columns = [
    {
      field: "material_type",
      headerName: "Type",
      flex: 3,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "material_vendor",
      headerName: "Manufacturer",
      flex: 4,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "material_style",
      headerName: "Product",
      flex: 4,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "material_color",
      headerName: "Color",
      flex: 3,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "material_size",
      headerName: "Size",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "size_unit",
      headerName: "Unit",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value.name}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "total_price",
      headerName: "Total $",
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.row.notes} arrow>
          <span style={{ color: params.row.main_item_id ? "blue" : "inherit" }}>
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "",
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => {
              const editableItem = materialItems.filter(
                (materialItem) => materialItem.id === params.row.id,
              )[0];
              setEditableMaterialItem(editableItem);
              setOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              setNewMainItemId(
                materialItems.filter(
                  (materialItem) => materialItem.id === params.row.id,
                )[0].id,
              );
              setOpen(true);
            }}
          >
            <PlaylistAddIcon />
          </IconButton>
        </div>
      ),
      width: 110,
    },
  ];

  const handleRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedMaterialItems(newSelectionModel);
    },
    [setSelectedMaterialItems],
  );

  const handleDialogClose = () => {
    setSelectedMaterialItems([]);
    setOpen(false);
    setEditableMaterialItem(null);
    setNewMainItemId("");
    setGridKey(!gridKey); // Force re-render
  };

  const listProjectMaterialItems = (isProposal) => {
    setMaterialsLoading(true);
    decoratedFetch(
      `/list_project_material_items?${isProposal ? "proposal_id" : "project_id"}=${isProposal ? selectedProposal.id : project_id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          setMaterialItems(data);
        } else {
          setMaterialItems([]);
        }
        setMaterialsLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error listing project materials: ${error}`);
        setLoading(false);
        setMaterialsLoading(false);
        setMaterialItems([]);
      });
  };

  const updateProjectMaterialSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateProjectKeepClean({
        ...project,
        material_subtotal: project.material_subtotal + itemTotalDiff,
      }),
    );
  };

  const updateProposalMaterialSubtotal = (itemTotalDiff) => {
    dispatchState(
      updateSelectedProposalKeepClean({
        ...selectedProposal,
        material_subtotal: selectedProposal.material_subtotal + itemTotalDiff,
      }),
    );
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    const isProposal =
      selectedProposal &&
      Object.keys(selectedProposal).length !== 0 &&
      selectedProposal.id !== "new_proposal";
    listProjectMaterialItems(isProposal);
    setIsExistingProposal(isProposal);
  }, [value, selectedProposal]);

  const handleDialogSubmit = (totalPrice, prevTotalPrice, isProposal) => {
    if (isProposal) {
      updateProposalMaterialSubtotal(totalPrice - prevTotalPrice);
    } else {
      updateProjectMaterialSubtotal(totalPrice - prevTotalPrice);
    }
    listProjectMaterialItems(isProposal);
  };

  const findAllDescendants = (ids) => {
    const allIds = new Set(ids);
    let idsToProcess = ids;

    while (idsToProcess.length) {
      let newIds = [];
      idsToProcess.forEach((id) => {
        materialItems.forEach((item) => {
          if (item.main_item_id === id && !allIds.has(item.id)) {
            allIds.add(item.id);
            newIds.push(item.id);
          }
        });
      });
      idsToProcess = newIds;
    }

    return Array.from(allIds);
  };

  const handleRemoveMaterialItems = () => {
    setLoading(true);
    const itemsToDelete = findAllDescendants(selectedMaterialItems);
    decoratedFetch(`/delete_project_material_items`, {
      method: "POST",
      body: JSON.stringify({
        material_item_ids: itemsToDelete,
        project_id: project.id,
        proposal_id: isExistingProposal ? selectedProposal.id : "",
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        // Calculate the total price of deleted items to update the subtotal
        const selectedMaterialItemTotalPrices = materialItems
          .filter((item) => itemsToDelete.includes(item.id))
          .map((item) => parseFloat(item.total_price));
        // Filter out deleted items from the local state
        setMaterialItems(
          materialItems.filter((item) => !itemsToDelete.includes(item.id)),
        );
        const positiveDiff = selectedMaterialItemTotalPrices.reduce(
          (sum, totalPrice) => sum + totalPrice,
          0,
        );
        if (isExistingProposal) {
          updateProposalMaterialSubtotal(-1 * positiveDiff);
        } else {
          updateProjectMaterialSubtotal(-1 * positiveDiff);
        }
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `Material${itemsToDelete.length > 1 ? "s" : ""} deleted`,
        );
        setSelectedMaterialItems([]);
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error deleting project material: ${error}`);
        setSnackbarOpen(true);
        setSnackbarMessage(
          `An error occurred when deleting material${selectedMaterialItems.length > 1 ? "s" : ""}`,
        );
      });
  };

  const getTreeDataPath = useCallback(
    (data) => {
      const path = [];
      let current = data;
      while (current) {
        path.push(current.id); // Append the current item's id to the path
        if (!current.main_item_id) break; // If there's no parent, break the loop
        current = materialItems.find(
          (item) => item.id === current.main_item_id,
        ); // Move to the parent
      }
      return path.reverse();
    },
    [materialItems],
  );

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <CreateMaterialItemDialog
        materialItem={editableMaterialItem}
        new_main_item_id={new_main_item_id}
        isOpen={open}
        handleClose={handleDialogClose}
        handleSubmit={handleDialogSubmit}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        proposalId={isExistingProposal ? selectedProposal.id : ""}
      />
      <div style={{ height: "500px", width: "100%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="right"
          sx={{ marginBottom: "10px" }}
        >
          {selectedMaterialItems.length ? (
            <Grid item sx={{ marginRight: "4px" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="remove-material-item"
                  onClick={handleRemoveMaterialItems}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Grid>
          ) : null}
          <Grid item>
            <IconButton
              aria-label="add-material-item"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <MaterialsTable
          rows={materialItems}
          columns={columns}
          loading={materialsLoading}
          getTreeDataPath={getTreeDataPath}
          handleRowSelectionModelChange={handleRowSelectionModelChange}
        />
      </div>
    </>
  );
}

export default MaterialItems;
