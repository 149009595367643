import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../../req_utils";
import { SUPPORT_EMAIL } from "../../constants";

const SavedLaborItemDialog = ({
  isOpen,
  laborItem,
  handleClose,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [laborItemType, setLaborItemType] = useState("");
  const [laborItemTypes, setLaborItemTypes] = useState([]);
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [demoItemType, setDemoItemType] = useState("");
  const [demoItemTypes, setDemoItemTypes] = useState([]);
  const [unitPrice, setUnitPrice] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setLaborItemType("");
    setDemoItemType("");
    setUnit("");
    setUnitPrice("");
    setLoading(false);
    setAlertMessage("");
  };

  const initializeFromLaborItemProp = () => {
    setLaborItemType(laborItem?.labor_item_type.id || "");
    setDemoItemType(laborItem?.demo_item_type || "");
    setUnit(laborItem.size_unit.id || "");
    setUnitPrice(laborItem?.unit_price || "");
  };

  //need to add the new apis
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (laborItem) {
      initializeFromLaborItemProp();
    } else {
      resetFields();
    }
    decoratedFetch("/list_demo_item_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setDemoItemTypes(data))
      .catch((error) => console.error(`Error fetching labor types ${error}`));
    decoratedFetch("/list_labor_item_types")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setLaborItemTypes(data))
      .catch((error) => console.error(`Error fetching labor types ${error}`));
    decoratedFetch("/list_size_units")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setUnits(data))
      .catch((error) => console.error(`Error fetching size units ${error}`));
  }, [isOpen]);

  const handleDelete = () => {
    setLoading(true);

    const url_suffix = `/delete_saved_labor_item/${laborItem.id}`;
    decoratedFetch(url_suffix, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          handleClose();
          setSnackbarOpen(true);
          setSnackbarMessage(`Labor item deleted`);
        } else {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        //setSeverity('error');
        setAlertMessage(
          `Failed to delete labor item. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        setLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!laborItemType || !unit) {
      setAlertMessage(
        "Required fields not filled in. Items must have a labor_item_type and unit",
      );
      setLoading(false);
      return;
    }
    const api = laborItem
      ? `/update_saved_labor_item/${laborItem.id}`
      : "/create_saved_labor_item";
    decoratedFetch(api, {
      method: laborItem ? "PUT" : "POST",
      body: JSON.stringify({
        labor_item_type_id: laborItemType,
        demo_item_type: demoItemType,
        unit: unit,
        unit_price: unitPrice ? unitPrice : parseFloat(0).toFixed(2),
      }),
    })
      .then((response) => {
        if (!laborItem && response.status === 201) {
          return;
        }
        if (laborItem && response.status === 200) {
          return;
        }
        switch (response.status) {
          case 400:
            setAlertMessage(
              "Required fields not filled in. Items must have a labor_item_type and unit",
            );
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error();
      })
      .then(() => {
        setLoading(false);
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(`Labor ${laborItem ? "updated" : "created"}`);
      })
      .catch((error) => {
        console.error(
          `Error ${laborItem ? "editing" : "creating"} labor: ${error}`,
        );
        setLoading(false);
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <Dialog
      disableScrollLock
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: "650px" }, // Targets the inner Paper component
      }}
    >
      <DialogTitle sx={{ fontSize: "22px", paddingBottom: "0px" }}>
        {laborItem ? "Edit Labor Item" : "Create Labor Item"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" sx={{ marginTop: "2px" }}>
          {alertMessage && (
            <Grid item xs={12}>
              <Alert
                onClose={handleAlertClose}
                severity="error"
                sx={{ width: "100%", borderRadius: 8 }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                width: "100%",
              }}
            >
              <InputLabel>Labor Type</InputLabel>
              <Select
                id="labor-labor_item_type-select"
                value={laborItemType}
                onChange={(e) => setLaborItemType(e.target.value)}
                label="Labor Type"
                fullWidth
              >
                {laborItemTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="DemoItemTypes"
              value={demoItemType}
              freeSolo
              options={demoItemTypes.map((option) => option.name)}
              renderInput={(params) => <TextField {...params} label="Item" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setDemoItemType(newInputValue);
                }
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setDemoItemType(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel>Unit</InputLabel>
              <Select
                id="unit-select"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                label="Unit"
                fullWidth
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Unit Price"
              id="unit price"
              type="number"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
              onBlur={() =>
                setUnitPrice(parseFloat(unitPrice).toFixed(2).toString())
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0, step: 0.01 },
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-end", margin: "5px", marginRight: "15px" }}
      >
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "20px" }}
          />
        ) : (
          <div>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {laborItem && (
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                disabled={alertMessage}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={alertMessage}
            >
              {laborItem ? "Save" : "Create"}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SavedLaborItemDialog;
