import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Paper,
  Grid,
  DialogActions,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";
import PhoneNumberFormat from "../PhoneNumberFormat";

export function CreateContactModal({
  isOpen,
  handleClose: handleCloseProp,
  triggerParentUpdate,
  contact,
  setSnackbarOpen,
  setSnackbarMessage,
  account_id,
  canDelete,
}) {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [cell_no, setCellNo] = useState("");
  const [office_no, setOfficeNo] = useState("");
  const [office_extension, setOfficeExtension] = useState("");
  const [fax_no, setFaxNo] = useState("");
  const [contactType, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isAddToAccountChecked, setIsAddToAccountChecked] = useState(false);
  const [hasAccountId, setHasAccountId] = useState(false);

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleCellNoChange = (event) => setCellNo(event.target.value);
  const handleOfficeNoChange = (event) => setOfficeNo(event.target.value);
  const handleFaxNoChange = (event) => setFaxNo(event.target.value);
  const handleTypeChange = (event) => setType(event.target.value);
  const handleOfficeExtensionChange = (event) => setOfficeExtension(event.target.value);
  const handleCheckboxChange = (event) => {
    setIsAddToAccountChecked(event.target.checked);
  };
  let { project_id } = useParams();
  const [contactTypes, setContactTypes] = useState([]);
  const actionButtonText = contact ? "Save" : "Create";

  useEffect(() => {
    if (contact) {
      setFirstName(contact?.first_name || "");
      setLastName(contact?.last_name || "");
      setCellNo(contact?.cell_no || "");
      setOfficeNo(contact?.office_no || "");
      setOfficeExtension(contact?.office_extension || "");
      setFaxNo(contact?.fax_no || "");
      setType(contact?.contact_type || "");
      setEmail(contact?.email || "");
      setIsAddToAccountChecked(Boolean(contact?.account_id));
      setHasAccountId(Boolean(contact?.account_id));
    } else {
      resetFields();
    }
  }, [contact]);

  const resetFields = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setCellNo("");
    setOfficeNo("");
    setOfficeExtension("");
    setFaxNo("");
    setType("");
    setLoading(false);
    setAlertMessage("");
    setSeverity("");
    setIsAddToAccountChecked(false);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    decoratedFetch("/list_contact_types")
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' should be an array of contact types, check if it's actually an array
        if (Array.isArray(data)) {
          setContactTypes(data);
        } else {
          console.error("Expected an array of contact types, received:", data);
          setContactTypes([]); // Set to an empty array if the response is not as expected
        }
      })
      .catch((error) => {
        console.error("Error fetching contact types:", error);
        setContactTypes([]); // Set to an empty array in case of an error
      });
  }, [isOpen]);

  const handleDelete = () => {
    setLoading(true);

    // Assuming you have a delete endpoint and contact.id is available
    const url_suffix = `/delete_contact/${contact.id}`;
    decoratedFetch(url_suffix, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          triggerParentUpdate();
          handleClose();
          setSnackbarOpen(true);
          setSnackbarMessage(`Contact deleted`);
        } else {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setAlertMessage(
          `Failed to delete contact. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        setLoading(false);
      });
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;

    // Remove non-numeric characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const handleSend = () => {
    setLoading(true);

    if (!contactType || !first_name || !last_name) {
      setSeverity("error");
      setAlertMessage(
        "Required fields not filled in. Contacts must have the following: Type, First Name, Last Name",
      );
      setLoading(false);
      return;
    }

    const url_suffix = contact
      ? `/update_contact/${contact.id}`
      : "/create_contact";
    const method = contact ? "PUT" : "POST";

    // Prepare the payload
    const payload = {
      email: email,
      contact_type: contactType,
      first_name: first_name,
      last_name: last_name,
      office_no: formatPhoneNumber(office_no),
      office_extension: office_extension,
      cell_no: formatPhoneNumber(cell_no),
      fax_no: formatPhoneNumber(fax_no),
    };

    if (account_id && !project_id) {
      // This checks for any "truthy" value including non-empty strings, non-zero numbers, etc.
      payload.account_id = account_id;
    } else if (project_id) {
      // This checks for any "truthy" value including non-empty strings, non-zero numbers, etc.
      payload.project_id = project_id;
      if (isAddToAccountChecked) {
        payload.account_id = account_id;
      }
    }

    // Make the POST request
    decoratedFetch(url_suffix, {
      method: method,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 201:
            return response.json();
          case 400:
            setAlertMessage(
              "Required fields not filled in. Contacts must have the following: Type, First Name, Last Name",
            );
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false);
        triggerParentUpdate();
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage(`Contact ${contact ? "updated" : "created"}`);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setAlertMessage(
          `Failed to ${contact ? "update" : "create"} contact. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        setLoading(false); // Stop loading in case of error
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetFields();
    handleCloseProp();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center", // Align items vertically
                justifyContent: "space-between", // Space between the typography and the checkbox
                width: "100%", // Full width
                padding: "8px", // Some padding around the elements
              }}
            >
              <Typography
                variant="h5"
                id="transition-modal-title"
                paddingBottom={1}
              >
                {contact ? "Edit Contact" : "Create Contact"}
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="Type"
                  required
                  value={contactType}
                  freeSolo
                  options={contactTypes.map((option) => option.display_name)}
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      setType(newInputValue);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setType(newValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  value={first_name}
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  value={last_name}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Cell No."
                  fullWidth
                  variant="outlined"
                  value={cell_no}
                  onChange={handleCellNoChange}
                  InputProps={{
                    inputComponent: PhoneNumberFormat,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Office No."
                  fullWidth
                  variant="outlined"
                  value={office_no}
                  onChange={handleOfficeNoChange}
                  InputProps={{
                    inputComponent: PhoneNumberFormat,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Ext"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={office_extension}
                  onChange={handleOfficeExtensionChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Fax No."
                  fullWidth
                  variant="outlined"
                  value={fax_no}
                  onChange={handleFaxNoChange}
                  InputProps={{
                    inputComponent: PhoneNumberFormat,
                  }}
                />
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                padding: 0,
                height: "40px",
              }}
            >
              {project_id && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAddToAccountChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Add to Account"
                  disabled={hasAccountId}
                  sx={{ width: "100%" }}
                />
              )}
              {loading ? (
                <CircularProgress size="35px" sx={{ mr: "30px", mt: "5px" }} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    paddingTop: 10,
                  }}
                >
                  <Button
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    onClick={handleClose}
                    disabled={alertMessage}
                  >
                    Cancel
                  </Button>
                  {(!hasAccountId || canDelete) && (
                    <Button
                      style={{ marginRight: 10 }}
                      variant="contained"
                      color="secondary"
                      onClick={handleDelete}
                      disabled={alertMessage}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={alertMessage}
                  >
                    {actionButtonText}
                  </Button>
                </div>
              )}
            </DialogActions>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
