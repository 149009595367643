import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Button, IconButton, Snackbar } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import * as mime from "react-native-mime-types";
import heic2any from "heic2any";
import {
  GENESIS_LOGO_COLOR,
  SUPPORT_EMAIL,
  SUPPORTED_UPLOAD_FILE_TYPES,
} from "../constants";
import { decoratedAxiosPost, decoratedAxiosPut } from "../req_utils";

const MAX_UPLOAD_SIZE_MB = 200;
const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;
const MAX_NUM_FILES = 50;

const FileUpload = ({ onSuccess }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let { project_id } = useParams();

  const convertHeicToJpeg = async (heicFile) => {
    try {
      const blob = await heic2any({
        blob: heicFile,
        toType: "image/jpeg",
        quality: 0.8,
      });
      return new File([blob], heicFile.name.replace(/\.heic$/, ".jpeg"), {
        type: "image/jpeg",
      });
    } catch (error) {
      console.error("Error converting HEIC to JPEG:", error);
    }
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    let files = Array.from(event.target.files);
    if (files.length === 0) {
      return;
    }
    if (files.length > MAX_NUM_FILES) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Max number of files (${MAX_NUM_FILES}) exceeded`);
      setLoading(false);
      return;
    }
    const uploadSize = files.reduce(
      (totalSize, file) => totalSize + file.size,
      0,
    );
    if (uploadSize > MAX_UPLOAD_SIZE) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Max upload size (${MAX_UPLOAD_SIZE_MB}MB) exceeded`);
      setLoading(false);
      return;
    }
    // Set conversion message in snackbar if at least one file is of 'heic' type
    let containsHeic = false;
    for (const file of files) {
      if (file.type == "image/heic") {
        containsHeic = true;
        setSnackbarMessage("Converting heic to jpeg...");
        setSnackbarOpen(true);
        break;
      }
    }
    // Convert any heic files to jpeg
    if (containsHeic) {
      files = await Promise.all(
        files.map((file) => {
          if (file.type == "image/heic") {
            return convertHeicToJpeg(file);
          }
          return file;
        }),
      );
      setSnackbarOpen(false);
    }

    try {
      const { data } = await decoratedAxiosPost(
        "/get_s3_presigned_urls_for_upload",
        {
          project_id: project_id,
          files: files.map((file) => ({
            name: file.name,
            type: file.type,
          })),
        },
      );

      await Promise.all(
        files.map((file, i) => {
          return axios.put(data[i].presigned_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
        }),
      );

      await decoratedAxiosPost("/create_project_files_metadata", {
        project_id: project_id,
        files: files.map((file, i) => ({
          name: file.name,
          type: mime.extension(file.type),
          s3_key: data[i].s3_key,
        })),
      });

      setSnackbarMessage(`File${files.length > 1 ? "s" : ""} uploaded`);
      setSnackbarOpen(true);
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.msg) {
        setSnackbarMessage(error.response.data.msg);
      } else {
        setSnackbarMessage(
          `An error occurred when uploading file${files.length > 1 ? "s" : ""}`,
        );
      }
      setSnackbarOpen(true);
      console.error(
        `Error uploading file${files.length > 1 ? "s" : ""}:`,
        error,
      );
    }
  };

  return (
    <div style={{ marginTop: "4px" }}>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {loading ? (
        <CircularProgress
          size="25px"
          sx={{ marginRight: "8px", marginTop: "7px", marginBottom: "1px" }}
        />
      ) : (
        <div>
          <input
            type="file"
            id="file-upload"
            multiple
            accept={SUPPORTED_UPLOAD_FILE_TYPES}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <IconButton aria-label="upload picture" component="span">
              <FileUploadIcon />
            </IconButton>
          </label>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
