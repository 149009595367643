import React, { useEffect, useState } from "react";
import { Snackbar, Button } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CreateProposalTemplateDialog from "./dialogs/CreateProposalTemplateDialog";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";

const ProposalTemplates = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [proposalTemplates, setProposalTemplates] = useState([]); // Adjust based on your actual state management
  const [proposalTemplatesLoading, setProposalTemplatesLoading] =
    useState(false); // Adjust based on your actual state management
  const [selectedItem, setSelectedItem] = useState(null); // Adjust based on your actual state management
  const [gridKey, setGridKey] = useState(false);

  const columns = [{ field: "name", headerName: "Name", flex: 1 }];

  const handleRowClick = (params) => {
    setSelectedItem(
      proposalTemplates.find(
        (proposalTemplate) => proposalTemplate.id === params.id,
      ),
    );
    setOpen(true);
  };

  const handleDialogSubmit = () => {
    setGridKey(!gridKey);
    handleDialogClose();
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    const listProposalTemplates = () => {
      decoratedFetch("/list_proposal_templates")
        .then((response) => response.json())
        .then((data) => {
          const mappedData = data.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setProposalTemplates(mappedData); // Set the new array to the state
        })
        .catch((error) => {
          console.error(`Error listing proposal templates: ${error}`);
        });
    };

    listProposalTemplates();
  }, [gridKey]);

  return (
    <div>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ my: "5px" }}
          onClick={() => setOpen(true)}
        >
          Create Proposal Template
        </Button>
      </div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGridPro
          rows={proposalTemplates}
          columns={columns}
          onRowClick={handleRowClick}
          loading={proposalTemplatesLoading}
          localeText={{ noRowsLabel: "No Templates" }}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
              {
                outline: "none",
              },
          }}
        />
      </div>
      <CreateProposalTemplateDialog
        open={open}
        proposalTemplate={selectedItem}
        handleClose={handleDialogClose}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        handleSubmit={handleDialogSubmit}
      />
    </div>
  );
};

export default ProposalTemplates;
